/* collapses
 ========================================================================== */
function showContent(btn, content) {
    var currentHeight = content.clientHeight + "px";
    content.classList.add('is-opened');
    var openedHeight = content.clientHeight + "px";
    content.style.height = currentHeight;
    setTimeout(function () {
        content.style.height = openedHeight;
    }, 0);
    content.addEventListener('transitionend', function () {
        content.style.height = 'auto';
    }, {
        once: true,
    });
    btn.setAttribute('aria-expanded', 'true');
}
function hideContent(btn, content) {
    var currentHeight = content.clientHeight + "px";
    content.classList.remove('is-opened');
    var closedHeight = content.clientHeight + "px";
    content.classList.add('is-opened');
    content.style.height = currentHeight;
    setTimeout(function () {
        content.style.height = closedHeight;
    }, 0);
    content.addEventListener('transitionend', function () {
        content.classList.remove('is-opened');
        content.style.height = 'auto';
    }, {
        once: true,
    });
    btn.setAttribute('aria-expanded', 'false');
}
export default function addCollapses() {
    var collapses = document.querySelectorAll('[data-component="collapse"]');
    collapses.forEach(function (collapse) {
        var btn = collapse.querySelector('[data-collapse-target="btn"]');
        btn.addEventListener('click', function () {
            var container = btn
                .closest('[data-component="collapse"]')
                .querySelector('[data-collapse-target="text"]');
            if (btn.getAttribute('aria-expanded') === 'false') {
                showContent(btn, container);
            }
            else {
                hideContent(btn, container);
            }
        });
    });
}
