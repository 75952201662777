/* main
 ========================================================================== */

// components
import addAccordions from './components/accordions/index';
import initAccount from './components/account';
import { handleEcardForm } from './components/account/ecard';
import updateMobileCartCounter from './components/cart/counter';
import characterCounter from './components/characterCounter';
import clickList from './components/clickList';
import addLookbookScroll from './components/cms/lookbook/scroll';
import { initDiaporamaLabelColor, initPageLabelColor } from './components/cms/menuLabelColor';
import addProductDialogs from './components/cms/product';
import addDialogs from './components/dialogs/index';
import setDisplayMode from './components/dropdowns/displayMode';
import addDropdowns from './components/dropdowns/index';
import showOtherAmount from './components/ecard/otherAmount';
import warningIfClosing from './components/ecard/warningIfClosing';
import initFooter from './components/footer';
import watchFormSubmit from './components/forms/index';
import watchForTimezone from './components/forms/return/timezone';
import { addGlobalEScripts, handleGlobalEBanner } from './components/globalE';
import initHeader from './components/header';
import addLazyLoad from './components/lazyLoad/index';
import ajaxSubscribeNewsletter from './components/newsletter/subscribe';
import addParallax from './components/parallax/index';
import ajaxAddProduct from './components/product/ajaxAddProduct';
import { renderProductCta } from './components/product/cta/ProductCta';
import radioCheckbox from './components/product/radioCheckbox';
import saveLastSeenProducts from './components/product/save';
import showLastSeenProducts from './components/product/show';
import addStickyProductBar from './components/product/sticky';
import productSwitcher from './components/product/switcher';
import toggleWishlistItem from './components/product/wishlist';
import productTabs from './components/product/tabs';
import updateQuickshops from './components/quickshop/updateQuickshop';
import ajaxSearch from './components/search/ajaxSearch';
import { autoSizeInput } from './components/search/autoSizeInput';
import addSliders from './components/sliders/index';
import addStateHover from './components/states/hover';
import addState from './components/states/index';
import addStickySubmenu from './components/submenu/index';
import switchImages from './components/switchImages/switchImages';
import addTabs from './components/tabs/index';
import updateCustomisations from './components/product/customisation';
import initPopins from './components/popins';
import addVideos from './components/videos/index';
import zoom from './components/zoom/index';
import shopTheLookDialog from './components/shopTheLook/dialog';
import initEcard from './components/ecard/index';
import sizeGuide from './components/dialogs/sizeGuide';
import productAutoRefresh from './components/product/autorefresh';
import handleNavigation from './components/navigation';
import './utils';
import initMultiProducts from './components/multiProducts';
import addCollapses from './components/collapse';
import addOffcanvas from './components/offcanvas';
import addFilters from './components/filters';
import { logAppVersion } from './logs';

logAppVersion();

document.addEventListener('DOMContentLoaded', () => {
  // add dropdowns
  addDropdowns();

  // add accordions
  addAccordions();

  // add tabs
  addTabs();

  // add state
  addState();

  // add state hover
  addStateHover();

  // add sliders
  addSliders();

  // defined menu label color
  initDiaporamaLabelColor();
  initPageLabelColor();

  // add videos
  addVideos();

  // add sticky submenu
  addStickySubmenu();

  // add sticky submenu
  addStickyProductBar();

  // add lazy load
  addLazyLoad();

  // add parallax
  addParallax();

  // update mobile cart product counter
  updateMobileCartCounter();

  // watch form submit to add display state (mobile/desktop)
  watchFormSubmit();

  // save shown product in localstorage
  saveLastSeenProducts();

  // show last seen products
  showLastSeenProducts();

  // add or remove a product from wishlist
  // We use a setTimeout for performance improvements on pages with lots of wishlists (ie /news-in)
  setTimeout(toggleWishlistItem, 0);

  // add product with ajax call (& related popin) on product page
  ajaxAddProduct();

  // radio checkbox
  radioCheckbox();

  // multiproducts
  initMultiProducts();

  // CMS lookbook-edito
  addLookbookScroll();

  // Block with product dialogs
  addProductDialogs();

  // Init account interactions
  initAccount();

  // Switch images for Instagram
  switchImages();

  // auto size input
  autoSizeInput();

  // character counter
  characterCounter();

  // zoom
  zoom();

  // Ajax search
  ajaxSearch();

  // show otherAmount field (or not) in ecard page
  showOtherAmount();

  // will prompt user if he's trying to close tab in ecard page
  warningIfClosing();

  productSwitcher();

  ajaxSubscribeNewsletter();

  clickList();

  setDisplayMode();

  updateQuickshops();

  updateCustomisations();

  handleEcardForm();

  // add dialogs
  addDialogs();

  renderProductCta();

  // GlobalE scripts
  addGlobalEScripts();
  handleGlobalEBanner();

  initHeader();

  initPopins();

  initFooter();

  shopTheLookDialog();

  initEcard();

  sizeGuide();

  productTabs();

  productAutoRefresh();

  addCollapses();

  addOffcanvas();

  addFilters();

  // watch return timezone form
  watchForTimezone();

  handleNavigation();
});
