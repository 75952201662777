var translations;
export default (function (path, values) {
    if (!translations) {
        translations = JSON.parse(document.querySelector('body').dataset.translations);
    }
    var trad = translations[path] || path;
    if (values) {
        Object.entries(values).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            trad = trad.replace("{" + key + "}", value);
        });
    }
    return trad;
});
