/* zoom
 ========================================================================== */

export default function zoom() {
  const zoomContainers = document.querySelectorAll('[data-role="zoom"]');

  zoomContainers.forEach((zoomContainer) => {
    let positionX;
    let positionY;

    const onMousemove = (event) => {
      positionX = event.offsetX / zoomContainer.offsetWidth * 100;
      positionY = event.offsetY / zoomContainer.offsetHeight * 100;

      zoomContainer.style.setProperty('--zoom-position-x', `${positionX}%`);
      zoomContainer.style.setProperty('--zoom-position-y', `${positionY}%`);
    };

    const onClick = (event) => {
      event.preventDefault();

      zoomContainer.classList.toggle('is-visible');
    };

    zoomContainer.addEventListener('mousemove', onMousemove);
    zoomContainer.addEventListener('click', onClick);
  });
}
