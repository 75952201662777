/* lib dialogs
 ========================================================================== */
import Dialogs from './a11y-dialog-component';
import routes from '../../routes';
import retrieveFormData from '../cart/retrieveFormData';
import { on } from '../../events';
import { setHTMLWithScripts } from '../../utils';
export var MODAL_LOAD_EVENT = 'load';
export var MODAL_CLOSE_EVENT = 'close';
var MODALS_CREATED = [];
var OTHER_MODALS_CREATED = [];
/**
 * By default async modals will have their content loaded when opened using the [data-modal-url] attribute.
 * Content will be cached by default, and exceptions can be added to this function (like quickshop for example)
 *
 * All async modals can listen to a custom "load" event, for example to init dynamic components.
 *
 */
var onOpenModal = function (dialog, trigger) {
    if (trigger) {
        var ajaxUrl = trigger.dataset.modalUrl;
        if (ajaxUrl) {
            var modalContentContainer_1 = dialog.querySelector('[data-modal-content]');
            var modalClass_1 = trigger.dataset.modalClass;
            // @ts-ignore This is a hack to attach data to the dom element
            dialog.previousHTML = modalContentContainer_1.innerHTML;
            // @ts-ignore This is a hack to attach data to the dom element
            dialog.previousClass = dialog.className;
            var linkedForm = trigger.dataset.form && (trigger.form || document.querySelector(trigger.dataset.form));
            // eslint-disable-next-line prefer-destructuring
            var query = trigger.dataset.query;
            if (!modalContentContainer_1) {
                // eslint-disable-next-line no-console
                console.warn('Missing [data-modal-content] for async dialog', dialog);
            }
            if (query) {
                ajaxUrl = ajaxUrl + "?" + query;
            }
            var options = {
                method: linkedForm ? 'POST' : 'GET',
                body: linkedForm ? retrieveFormData(linkedForm) : null,
            };
            fetch(ajaxUrl, options).then(function (response) {
                if (response.status === 401) {
                    setTimeout(function () {
                        window.redirect(routes.connect + "?redirect=" + window.location.href);
                    }, 3000);
                }
                response.text().then(function (content) {
                    if (modalClass_1) {
                        dialog.className = modalClass_1;
                    }
                    setHTMLWithScripts(modalContentContainer_1, content);
                    dialog.dispatchEvent(new CustomEvent(MODAL_LOAD_EVENT, { detail: { role: trigger.dataset.role } }));
                });
            });
        }
        else {
            dialog.dispatchEvent(new CustomEvent(MODAL_LOAD_EVENT, { detail: { role: trigger.dataset.role } }));
        }
    }
};
/**
 * Used to reset modal to loading state if needed
 *
 * @param {Dialogs.Dialog} instance
 */
var onCloseModal = function (instance) {
    if (instance.trigger && instance.trigger.dataset.modalUrl) {
        instance.dialog.dispatchEvent(new CustomEvent(MODAL_CLOSE_EVENT, { detail: { role: instance.trigger.dataset.role } }));
        var modalContentContainer = instance.dialog.querySelector('[data-modal-content]');
        instance.dialog.className.replace(instance.dialog.previousClass);
        if (modalContentContainer) {
            modalContentContainer.innerHTML = instance.dialog.previousHTML;
        }
    }
};
// init dialogs based on data components
export function initDialogs(_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.documentClass, documentClass = _c === void 0 ? 'js-document' : _c, _d = _b.documentDisabledClass, documentDisabledClass = _d === void 0 ? 'is-disabled' : _d, _e = _b.documentFixedClass, documentFixedClass = _e === void 0 ? 'is-fixed' : _e, _f = _b.triggerActiveClass, triggerActiveClass = _f === void 0 ? 'is-active' : _f;
    on('[data-component="dialog"]', 'click', function (element) {
        var triggerId = element.id;
        var target = element.dataset.target;
        // Prevent conflict with already created modals
        if (OTHER_MODALS_CREATED.includes(target)) {
            return;
        }
        if (!triggerId) {
            triggerId = Math.random().toString(36).substr(2, 9);
            element.id = triggerId;
        }
        /**
         * Keep track of modals initialized
         * This weird hack is to allow one modal to be opened by multiple different triggers (ie ajax modals)
         * and prevent the trigger attributes modification caused by the a11y-dialog-component destroy method.
         */
        if (!MODALS_CREATED.includes(target)) {
            MODALS_CREATED.push(target);
            Dialogs.create(target, {
                triggerId: triggerId,
                show: true,
                onClose: onCloseModal,
                // @ts-ignore
                documentClass: documentClass,
                documentDisabledClass: documentDisabledClass,
                documentFixedClass: documentFixedClass,
                triggerActiveClass: triggerActiveClass,
            });
        }
        else {
            Dialogs.show(target);
        }
        var dialog = document.getElementById(target);
        onOpenModal(dialog, element);
    });
    // Create the ajax modals before for some uses (ie cross sell)
    Dialogs.create('ajax-modal', {
        onOpen: onOpenModal,
        onClose: onCloseModal,
    });
    Dialogs.create('ajax-modal2', {
        onOpen: onOpenModal,
        onClose: onCloseModal,
    });
    Dialogs.create('dialog-switch-site', {
        onClose: function () {
            // Reset site link on close
            var siteLink = document.querySelector('a[data-switch-site-link]');
            if (siteLink) {
                siteLink.href = "/choose-country-region/";
            }
        },
    });
}
export default function addDialog(id, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.breakpoint, breakpoint = _c === void 0 ? null : _c, _d = _b.breakpointPlus, breakpointPlus = _d === void 0 ? false : _d, _e = _b.triggerId, triggerId = _e === void 0 ? null : _e, _f = _b.labelledbyId, labelledbyId = _f === void 0 ? null : _f, _g = _b.describedbyId, describedbyId = _g === void 0 ? null : _g, _h = _b.show, show = _h === void 0 ? false : _h, _j = _b.modal, modal = _j === void 0 ? true : _j, _k = _b.tooltip, tooltip = _k === void 0 ? false : _k, _l = _b.backdrop, backdrop = _l === void 0 ? true : _l, _m = _b.disableScroll, disableScroll = _m === void 0 ? true : _m, _o = _b.disableIosScroll, disableIosScroll = _o === void 0 ? false : _o, _p = _b.disableEscape, disableEscape = _p === void 0 ? false : _p, _q = _b.transitionDuration, transitionDuration = _q === void 0 ? 200 : _q;
    var dialog = document.getElementById(id);
    var isCreated = false;
    if (!dialog)
        return;
    var createDialog = function () {
        Dialogs.create(id, {
            triggerId: triggerId,
            labelledbyId: labelledbyId,
            describedbyId: describedbyId,
            show: show,
            modal: modal,
            tooltip: tooltip,
            backdrop: backdrop,
            disableScroll: disableScroll,
            disableIosScroll: disableIosScroll,
            disableEscape: disableEscape,
            transitionDuration: transitionDuration,
        });
        OTHER_MODALS_CREATED.push(id);
    };
    var destroyDialog = function () {
        Dialogs.destroy(id);
    };
    var setDialogAbove = function () {
        if (window.innerWidth > breakpoint && !isCreated) {
            isCreated = true;
            createDialog();
        }
        else if (window.innerWidth <= breakpoint && isCreated) {
            isCreated = false;
            destroyDialog();
        }
    };
    var setDialogBelow = function () {
        if (window.innerWidth <= breakpoint && !isCreated) {
            isCreated = true;
            createDialog();
        }
        else if (window.innerWidth > breakpoint && isCreated) {
            isCreated = false;
            destroyDialog();
        }
    };
    var onLoad = function () {
        breakpointPlus ? setDialogAbove() : setDialogBelow();
    };
    var onResize = function () {
        breakpointPlus ? setDialogAbove() : setDialogBelow();
    };
    if (breakpoint) {
        window.addEventListener('load', onLoad);
        window.addEventListener('resize', onResize);
    }
    else {
        createDialog();
    }
}
