/* edito cms
 ========================================================================== */

import addStateHover from '../../states/hover';
import addVideos from '../../videos';
import Addstate from '../../states';
import updateQuickShop from '../../quickshop/updateQuickshop';
import { initDropdowns } from '../../dropdowns/lib';
/* eslint-disable */
import LazyLoad from 'vanilla-lazyload';
import { updateWishlist } from "../../product/wishlist";

export default function init() {
  const submenu = document.querySelector('.js-lookbook-scroll');

  if (!submenu) {
    return;
  }

  const content = submenu.querySelector('[data-role=content');
  const listItems = submenu.querySelectorAll('[data-role=listitem');

  if (!content || !listItems) return;

  listItems.forEach((item) => {
    item.addEventListener('click', () => {
      content.innerHTML = item.dataset.lookTemplate;
      addStateHover(content);
      addVideos(content);
      Addstate(content);
      initDropdowns();
      updateQuickShop();
      updateWishlist();

      new LazyLoad({
        elements_selector: '.js-lazy',
      });
    });
  });
}
