import React from 'react';
import translate from '../../../../translate';
import routes from '../../../../routes';
import analytics from '../../../../analytics';
var AddAlertButton = function (_a) {
    var _b = _a.soon, soon = _b === void 0 ? false : _b, tracking = _a.tracking;
    return (React.createElement("span", { className: "c-tooltip c-tooltip--secondary c-tooltip--full js-create-alert-button" },
        React.createElement("button", { type: "button", className: "c-btn c-btn--alert c-tooltip__trigger u-w-100", "data-component": "dialog", "data-modal-url": routes.user_alert_add_ajax, "data-form": "form[data-cart-add-product]", "data-query": "soon=" + soon.toString(), "data-target": "ajax-modal2", onClick: function () {
                tracking && analytics.productAlert(tracking);
            } }, translate('front.product.index.createAlert'))));
};
export default AddAlertButton;
