/* filters
========================================================================== */
var selectedFilters = {
    c: [],
    t: [],
    s: []
};
function setSelectedFilters(links) {
    links.forEach(function (link) {
        var linkType = link.dataset.type;
        var linkUuid = '';
        if (link.dataset.uuid) {
            linkUuid = link.dataset.uuid;
        }
        else if (link.dataset.uuids) {
            linkUuid = JSON.parse(link.dataset.uuids);
        }
        if (link.classList.contains('is-selected')) {
            if (Array.isArray(linkUuid)) {
                linkUuid.forEach(function (uuid) {
                    selectedFilters[linkType].push(uuid);
                });
            }
            else {
                selectedFilters[linkType].push(linkUuid);
            }
        }
    });
}
function selectFilter(e) {
    e.preventDefault();
    var link = e.currentTarget;
    var linkType = link.dataset.type;
    var linkUuid = '';
    if (link.dataset.uuid) {
        linkUuid = link.dataset.uuid;
    }
    else if (link.dataset.uuids) {
        linkUuid = JSON.parse(link.dataset.uuids);
    }
    if (link.classList.contains('is-selected')) {
        link.classList.remove('is-selected');
        var typedLinks = [];
        if (Array.isArray(linkUuid)) {
            typedLinks = selectedFilters[linkType].filter(function (uuid) {
                return !linkUuid.includes(uuid);
            });
        }
        else {
            typedLinks = selectedFilters[linkType].filter(function (uuid) {
                return uuid !== linkUuid;
            });
        }
        selectedFilters[linkType] = typedLinks;
    }
    else {
        link.classList.add('is-selected');
        if (Array.isArray(linkUuid)) {
            linkUuid.forEach(function (uuid) {
                selectedFilters[linkType].push(uuid);
            });
        }
        else {
            selectedFilters[linkType].push(linkUuid);
        }
    }
}
function updateLink(link) {
    var linkBase = link.dataset.linkBase;
    var linkTypes = Object.keys(selectedFilters);
    linkTypes.forEach(function (type) {
        selectedFilters[type].forEach(function (uuid) {
            if (linkBase === link.dataset.linkBase) {
                linkBase += '?';
            }
            else {
                linkBase += '&';
            }
            linkBase += type + "[]=" + uuid;
        });
    });
    link.setAttribute('href', encodeURI(linkBase));
}
export default function addFilters() {
    var filters = document.querySelectorAll('[data-component="filters"]');
    filters.forEach(function (el) {
        var links = el.querySelectorAll('[data-filters-target="link"]');
        var searchLink = el.querySelector('[data-filters-target="search"]');
        setSelectedFilters(links);
        updateLink(searchLink);
        links.forEach(function (link) {
            link.addEventListener('click', function (e) {
                selectFilter(e);
                updateLink(searchLink);
            });
        });
    });
}
