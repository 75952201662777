import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';
import { ADD_TO_CART, IS_ADDING_TO_CART, PRODUCT_STATE_CHANGE } from '../../../events';
import { getSite } from '../../../utils';
import AddAlertButton from './buttons/AddAlertButton';
import AddToBasketButton from './buttons/AddToBasketButton';
import AvailableSoonButton from './buttons/AvailableSoonButton';
import LastMinuteShoppingButton from './buttons/LastMinuteShoppingButton';
import SiteAvailabilityButton from './buttons/SiteAvailabilityButton';
import LastCall from './messages/LastCall';
/**
 * Each button is linked with product hash, each time a new variant or item is selected, an event is dispatched with the selected hash.
 * Buttons are listening to this event to update accordingly.
 */
export var ProductCta = function (_a) {
    var _b = _a.onlyBasketCta, onlyBasketCta = _b === void 0 ? false : _b, allowAddedStatus = _a.allowAddedStatus, product = _a.product, inDialogContext = _a.inDialogContext;
    var size = !inDialogContext && window.location.hash.replace('#size-', '');
    // Local scoped uniq id to target buttons with custom events
    var idButton = useState(uuidv4())[0];
    var _c = useState(document.body.dataset.variant && !inDialogContext
        ? product.variants.find(function (variant) { return variant.id === Number(document.body.dataset.variant); })
        : product.selectedVariant), selectedVariant = _c[0], setSelectedVariant = _c[1];
    var defaultSelectedItem = selectedVariant.selectedItem;
    if (size) {
        var selectedItem_1 = selectedVariant.items.find(function (item) { return item.size === size; });
        if (selectedItem_1) {
            defaultSelectedItem = selectedItem_1;
        }
    }
    var _d = useState(defaultSelectedItem), selectedItem = _d[0], setSelectedItem = _d[1];
    var _e = useState(false), addedToCart = _e[0], setAddedToCart = _e[1];
    var _f = useState(undefined), isLoadingId = _f[0], setIsLoadingId = _f[1];
    // Listen to product added to cart
    useEffect(function () {
        var onProductAddedToCart = function (e) {
            if (allowAddedStatus && e.detail.ean === selectedItem.ean) {
                setAddedToCart(true);
            }
        };
        document.addEventListener(ADD_TO_CART, onProductAddedToCart);
        return function () {
            document.removeEventListener(ADD_TO_CART, onProductAddedToCart);
        };
    }, [selectedItem]);
    useEffect(function () {
        var isAddingProductToCart = function (e) {
            if (e.detail.id.includes(idButton)) {
                setIsLoadingId(e.detail.loading ? e.detail.id : undefined);
            }
        };
        document.addEventListener(IS_ADDING_TO_CART, isAddingProductToCart);
        return function () {
            document.removeEventListener(IS_ADDING_TO_CART, isAddingProductToCart);
        };
    }, [selectedItem]);
    // Listen to product state change (new variant or new item selected)
    useEffect(function () {
        if (inDialogContext) {
            var onProductStateChange_1 = function (e) {
                var data = e.detail;
                if (data.productHash === product.hash) {
                    if (data.currentVariantHash) {
                        var variant = product.variants.find(function (productVariant) { return productVariant.hash === data.currentVariantHash; });
                        if (variant) {
                            setSelectedVariant(variant);
                            setSelectedItem(data.currentItemHash
                                ? variant.items.find(function (item) { return item.hash === data.currentItemHash; })
                                : variant.selectedItem);
                        }
                    }
                    else if (data.currentItemHash) {
                        var newSelectedItem = selectedVariant.items.find(function (item) { return item.hash === data.currentItemHash; });
                        if (newSelectedItem) {
                            setSelectedItem(newSelectedItem);
                        }
                    }
                }
            };
            document.addEventListener(PRODUCT_STATE_CHANGE, onProductStateChange_1);
            return function () {
                document.removeEventListener(PRODUCT_STATE_CHANGE, onProductStateChange_1);
            };
        }
    }, []);
    // Listen to size change (product page)
    useEffect(function () {
        if (!inDialogContext) {
            var onPopState_1 = function () {
                var hashSize = decodeURIComponent(window.location.hash.replace('#size-', ''));
                if (selectedItem && hashSize !== selectedItem.size) {
                    setSelectedItem(selectedVariant.items.find(function (item) { return item.size === hashSize; }));
                }
            };
            window.addEventListener('popstate', onPopState_1);
            return function () {
                window.removeEventListener('popstate', onPopState_1);
            };
        }
    }, [selectedItem]);
    if (!selectedVariant || !selectedItem) {
        return null;
    }
    var siteCode = getSite();
    var isPreorder = selectedVariant.preorder;
    var isAvailableSoon = selectedVariant.availableSoon;
    var isCustomisation = !!selectedVariant.customisation;
    var isAvailable = selectedItem.available;
    var isInOtherBasket = selectedItem.inOtherBasket;
    var lastMinuteShoppingAvailable = selectedItem.lastMinuteShoppingAvailable && siteCode === 'fr';
    var isCrossSellPopin = selectedVariant.isCrossSellPopin;
    var displaySiteAvailability = false;
    var selectedVariantTracking = selectedVariant.tracking;
    var isLastCall = selectedItem.isLastCall && isAvailable && !isInOtherBasket && !onlyBasketCta;
    var tracking = {
        brand: selectedVariantTracking.brand,
        category: selectedVariantTracking.category,
        ean: selectedItem.ean,
        name: selectedVariantTracking.name,
        price: selectedVariantTracking.productPrice,
        variantColor: selectedVariantTracking.variantColor,
        content_ids: selectedVariantTracking.legacyId,
        productUUID: selectedVariantTracking.productUUID,
        sizeUUID: selectedItem.uuid,
        legacyId: selectedVariantTracking.legacyId,
        productBrand: selectedVariantTracking.productBrand,
        productCategory: selectedVariantTracking.productCategory,
        productEAN: selectedVariantTracking.productEAN,
        productID: selectedVariantTracking.productID,
        productName: selectedVariantTracking.productName,
        productPrice: selectedVariantTracking.productPrice,
        productStatus: selectedVariantTracking.productStatus,
        productURL: selectedVariantTracking.productURL,
        productVariant: selectedVariantTracking.productVariant,
        fsColor: selectedVariantTracking.fsColor,
        color: selectedVariantTracking.color,
        collection: selectedVariantTracking.collection,
    };
    var mainCta = React.createElement(AddAlertButton, { soon: isAvailableSoon, tracking: tracking });
    if (isAvailableSoon) {
        mainCta = React.createElement(AvailableSoonButton, null);
    }
    else if (isAvailable || isInOtherBasket) {
        mainCta = (React.createElement(AddToBasketButton, { isAvailable: isAvailable, isPreorder: isPreorder, isInOtherBasket: isInOtherBasket, isCustomisation: isCustomisation, preorderDate: selectedVariant.preorderDate, tracking: tracking, withTooltip: !onlyBasketCta, popinCrossSellPath: isCrossSellPopin && selectedVariant.link, ean: selectedItem.ean, addedToCart: addedToCart, isLoading: isLoadingId === idButton, id: idButton }));
    }
    if (onlyBasketCta || addedToCart) {
        return mainCta;
    }
    var lastMinuteIdButton = idButton + "-lms";
    var isLastMinuteLoading = isLoadingId === lastMinuteIdButton;
    return (React.createElement("div", null,
        !isAvailable && !isPreorder && !isInOtherBasket ? (React.createElement(React.Fragment, null,
            lastMinuteShoppingAvailable && (React.createElement(LastMinuteShoppingButton, { tracking: tracking, id: lastMinuteIdButton, isLoading: isLastMinuteLoading, className: "u-mb-sm" })),
            mainCta)) : (React.createElement(React.Fragment, null,
            mainCta,
            lastMinuteShoppingAvailable && (React.createElement(LastMinuteShoppingButton, { tracking: tracking, id: lastMinuteIdButton, isLoading: isLastMinuteLoading, className: "c-btn--senary u-mt-sm" })))),
        displaySiteAvailability && React.createElement(SiteAvailabilityButton, null),
        isLastCall && React.createElement(LastCall, null)));
};
// clear vdom to avoid unlinked instances in the wild
export var clearCtas = function () {
    return document
        .querySelectorAll('[data-product-cta]')
        .forEach(function (ctaContainer) { return createRoot(ctaContainer).render(null); });
};
export var renderProductCta = function (config) {
    var _a = config || {}, _b = _a.container, container = _b === void 0 ? document : _b, _c = _a.inDialogContext, inDialogContext = _c === void 0 ? false : _c;
    container.querySelectorAll('[data-product-cta]').forEach(function (ctaContainer) {
        var onlyBasketCta = ctaContainer.hasAttribute('data-only-basket-cta');
        var allowAddedStatus = ctaContainer.hasAttribute('data-allow-added-status');
        // The product state is either on the button (ie in modals) or global (product page)
        var product = ctaContainer.dataset.product
            ? JSON.parse(ctaContainer.dataset.product)
            : window.product;
        createRoot(ctaContainer).render(React.createElement(ProductCta, { onlyBasketCta: onlyBasketCta, allowAddedStatus: allowAddedStatus, product: product, inDialogContext: inDialogContext }));
    });
};
