var domParser = new DOMParser();
function getLoaderHtml(className) {
    if (className === void 0) { className = 'c-loader c-loader--button c-loader--fullscreen'; }
    var html = "<div class=\"" + className + " u-m-auto\">\n  <div class=\"c-loader__spinner\"></div>\n  </div>";
    return domParser.parseFromString(html, 'text/html').body.childNodes[0];
}
function showLoader(target, options) {
    var loader = getLoaderHtml(options.className);
    var content = target.innerHTML;
    if (options.hideContent) {
        target.innerHTML = "<span class=\"u-visibility-hidden\">" + target.innerHTML + "</span>";
    }
    else {
        target.innerHTML = '';
    }
    target.appendChild(loader);
    return function () {
        try {
            if (options.hideContent) {
                target.innerHTML = content;
            }
            else {
                target.removeChild(loader);
            }
            // eslint-disable-next-line no-empty
        }
        catch (_a) { }
    };
}
export default showLoader;
