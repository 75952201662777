/* lazyload
 ========================================================================== */

/* eslint-disable */
import LazyLoad from 'vanilla-lazyload';

export default function addLazyLoad() {
  new LazyLoad({
    elements_selector: '.js-lazy',
    threshold: 50,
  });
}
