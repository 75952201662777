/* quickshop
 ========================================================================== */

import { initDropdowns } from '../dropdowns/lib';
import { MODAL_CLOSE_EVENT, MODAL_LOAD_EVENT } from '../dialogs/lib';
import ajax from './ajax';
import { renderProductCta } from '../product/cta/ProductCta';
import { PRODUCT_STATE_CHANGE } from '../../events';
import analytics from '../../analytics';

/**
 * @param {Element} quickshop
 */
function updateQuickShop(quickshop) {
  const setDropdownTriggerElem = (id, state, currentVariant) => {

    const imageUrl = currentVariant.photos.length ? currentVariant.photos[0].imageUrl : '';

    return `
      <button type="button" id="dropdown-quickshop-trigger-${id}" class="c-switch__btn" data-component="dropdown" data-hover="true" data-target="dropdown-quickshop-${id}">
        <img src="${imageUrl}" width="43" height="60" alt="" />
        <span class="c-switch__text">${currentVariant.name}</span>
        ${
          state.variants.length > 1
            ? `<i class="c-icon c-switch__icon u-mr-xs">
          <svg width="36" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.2 6.8" class="c-icon__svg" aria-hidden="true">
            <path d="M5.6 4.4L10 0l1.2 1.2-5.6 5.6L0 1.2 1.2 0l4.4 4.4z"></path>
          </svg>
        </i>`
            : ''
        }
      </button>`;
  };

  const setDropdownListElem = (id, state, currentVariant) => {
    let domString = `<ul id="dropdown-quickshop-${id}" class="c-switch__list">`;

    state.variants.forEach((variant) => {
      const imageUrl = variant.photos.length ? variant.photos[0].imageUrl : '';
      if (variant.name !== currentVariant.name) {
        domString += `<li class="c-switch__item" data-item>
                        <button type="button" class="c-switch__btn c-switch__btn--on" data-focus data-quickshop-switch-trigger="${variant.hash}">
                            <img src="${imageUrl}" width="43" height="60" alt="" />
                            <span class="c-switch__text">${variant.name}</span>
                        </button>
                    </li>`;
      }
    });

    domString += '</ul>';

    return domString;
  };

  const setSizeListElem = (id, currentVariant) => {
    let domString = '';

    currentVariant.items.forEach((item) => {
      let isDisabled = '';
      if (!currentVariant.available || (!item.available && !item.lastMinuteShoppingAvailable)) {
        isDisabled = ' is-disabled';
      }

      domString += `<li class="o-grid__col">
                        <input id="size-cms-product-${item.hash}" name="ean[desktop][]" value="${
        item.ean
      }" class="u-visually-hidden" type="radio" data-quickshop-item-trigger="${item.hash}" ${
        currentVariant.selectedItem.hash === item.hash ? 'checked' : ''
      } />
                        <label for="size-cms-product-${item.hash}" class="c-size${isDisabled}">${item.size}</label>
                    </li>`;
    });

    return domString;
  };

  const setState = (currentVariantHash, currentItemHash, state) => {
    if (currentVariantHash != null) {
      state.variants.forEach((variant, index) => {
        variant.hash === currentVariantHash
          ? (state.variants[index].selected = true)
          : (state.variants[index].selected = false);
      });
    }
    if (currentItemHash != null) {
      state.variants.forEach((variant, index) => {
        let variantHashMatch = false;
        variant.items.forEach((item) => {
          if (item.hash === currentItemHash) {
            state.variants[index].selectedItem = item;
            variantHashMatch = true;
          }
        });

        variantHashMatch ? (state.variants[index].selected = true) : (state.variants[index].selected = false);
      });
    }
  };

  const setDropdownElem = (id, state, currentVariant) => {
    const dropdown = quickshop.querySelector('[data-quickshop-dropdown]');
    const dropdownTrigger = setDropdownTriggerElem(id, state, currentVariant);
    const dropdownList = setDropdownListElem(id, state, currentVariant);

    dropdown.innerHTML = dropdownTrigger + dropdownList;
  };

  const setSizeElem = (id, currentVariant) => {
    const size = quickshop.querySelector('[data-quickshop-size]');
    const sizeList = setSizeListElem(id, currentVariant);

    size.innerHTML = sizeList;
  };

  const setProductLink = (currentVariant) => {
    const productLink = quickshop.querySelector('[data-quickshop-product-link]');

    productLink.setAttribute('href', currentVariant.link);
  };

  const setMediaElem = (currentVariant) => {
    const mediaElem = quickshop.querySelector('[data-quickshop-media] img');

    mediaElem.setAttribute('src', currentVariant.photos.length ? currentVariant.photos[0].imageUrl : '');
  };

  const setPriceElem = (currentVariant) => {
    const priceElem = quickshop.querySelector('.js-dialog-quickshop-price');

    priceElem.innerHTML = currentVariant.displayPrice;
    if (
      typeof currentVariant.crossedOutPrice !== 'undefined' &&
      currentVariant.crossedOutPrice !== currentVariant.price
    ) {
      priceElem.innerHTML = `<s>${currentVariant.displayCrossedOutPrice}</s>&nbsp;${currentVariant.displayPrice}`;
    }
  };

  const content = quickshop.querySelector('[data-quickshop-state]');

  if (!content) {
    // eslint-disable-next-line no-console
    console.warn('Missing quickshop state attribute [data-quickshop-state]');

    return;
  }

  const state = JSON.parse(content.dataset.quickshopState);
  const id = state.productId;

  const onClick = (currentVariantHash, currentItemHash) => {
    setState(currentVariantHash, currentItemHash, state);
    const currentVariant = state.variants.find((variant) => variant.selected);
    setDropdownElem(id, state, currentVariant);
    setSizeElem(id, currentVariant);
    setProductLink(currentVariant);
    setMediaElem(currentVariant);
    setPriceElem(currentVariant);
    document.dispatchEvent(
      new CustomEvent(PRODUCT_STATE_CHANGE, {
        detail: {
          productHash: state.hash,
          currentVariantHash: currentVariant.hash,
          currentItemHash,
        },
      }),
    );

    initDropdowns();
    ajax(quickshop);

    quickshop.querySelectorAll('[data-quickshop-switch-trigger]').forEach((trigger) => {
      trigger.addEventListener('click', onClick.bind(this, trigger.dataset.quickshopSwitchTrigger, null));
    });
    quickshop.querySelectorAll('[data-quickshop-item-trigger]').forEach((trigger) => {
      trigger.addEventListener('change', onClick.bind(this, null, trigger.dataset.quickshopItemTrigger));
    });
  };

  quickshop.querySelectorAll('[data-quickshop-switch-trigger]').forEach((trigger) => {
    trigger.addEventListener('click', onClick.bind(this, trigger.dataset.quickshopSwitchTrigger, null));
  });
  quickshop.querySelectorAll('[data-quickshop-item-trigger]').forEach((trigger) => {
    trigger.addEventListener('change', onClick.bind(this, null, trigger.dataset.quickshopItemTrigger));
  });

  initDropdowns();
  ajax(quickshop);
}

export default function updateQuickshops() {
  const dialog = document.querySelector('[data-ajax-modal]');

  dialog.addEventListener(MODAL_LOAD_EVENT, (e) => {
    if (e.detail.role === 'quickshop') {
      const quickshop = e.target;
      updateQuickShop(quickshop);
      renderProductCta({ container: quickshop, inDialogContext: true });
      const tracking = JSON.parse(quickshop.querySelector('[data-tracking]').dataset.tracking);
      analytics.quickshopViewed(tracking);
    }
  });

  dialog.addEventListener(MODAL_CLOSE_EVENT, (e) => {
    if (e.detail.role === 'quickshop') {
      const quickshop = e.target;
      const tracking = JSON.parse(quickshop.querySelector('[data-tracking]').dataset.tracking);
      analytics.quickshopClosed(tracking);
    }
  });
}
