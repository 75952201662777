var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export default function characterCounter() {
    var counters = document.querySelectorAll('[data-role="character-counter"]');
    counters.forEach(function (counter) {
        var counterDisplayTarget = document.querySelector(counter.dataset.characterCounterTarget);
        var maxLength = Number(counter.dataset.maxLength);
        if (maxLength) {
            // Since maxLength attribute doesn't work properly with emojis, we need to manually implement it's behavior
            counter.addEventListener('input', function () {
                var valueArray = __spreadArray([], Array.from(counter.value), true).splice(0, maxLength);
                counter.value = valueArray.join('');
                counterDisplayTarget.textContent = "" + (maxLength - valueArray.length);
            });
        }
    });
}
