var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GeolocDialog } from '@sezane/front-components';
import Cookies from 'js-cookie';
import React from 'react';
import { createRoot } from 'react-dom/client';
import analytics from '../../analytics';
import { getDefaultCountry, getMapCountrySite, getUserAgentCountry, GLOBALE_COUNTRIES_CURRENCIES, } from '../../countries';
import { getSites } from '../../sites';
import translate from '../../translate';
import { isWebview } from '../../utils';
var GLOBALE_COOKIE = 'GlobalE_Data';
var mapCountrySite = getMapCountrySite();
export var getLocale = function () {
    var globalEData = getGlobalEData();
    return (globalEData === null || globalEData === void 0 ? void 0 : globalEData.locale) || document.documentElement.lang.split('-')[0];
};
// we don't want to use the js-cookie default encoder because
// we want: %7B%22countryISO%22:%22FR%22,%22currencyCode%22:%22EUR%22%7D
// and the result is {%22countryISO%22:%22FR%22%2C%22currencyCode%22:%22EUR%22}
// so we need to encodeURI before and use this cookieWithConverter
var cookieWithConverter = Cookies.withConverter({
    write: function (value) {
        return value;
    },
    read: function (value) {
        return value;
    },
});
var GLOBALE_BANNER_DIALOG = 'dialog-globalE-banner';
// eslint-disable-next-line import/no-mutable-exports
export var GLOBALE_INITIALIZED = false;
var getGlobalECookieParams = function () {
    var domain = window.location.hostname.replace(/(www-nocache|www)/, '');
    return {
        domain: domain,
        path: '/',
        'Max-Age': (182 * 24 * 60 * 60).toString(),
        'Same-Site': 'Lax',
    };
};
export var getGlobalEData = function () {
    var globalECookie = cookieWithConverter.get(GLOBALE_COOKIE);
    var globalEData;
    try {
        try {
            globalEData = JSON.parse(decodeURIComponent(globalECookie));
        }
        catch (_a) {
            // because there are some cookies with double encoded value
            // we try to decode it twice and put the result of the first decode
            // in the cookie
            var firstDecodeGlobalECookie = decodeURIComponent(globalECookie);
            globalEData = JSON.parse(decodeURIComponent(firstDecodeGlobalECookie));
            cookieWithConverter.set(GLOBALE_COOKIE, firstDecodeGlobalECookie, getGlobalECookieParams());
        }
    }
    catch (_b) {
        // eslint-disable-next-line no-console
        console.error("Couldn't read GlobalE cookie value.", globalECookie);
    }
    return globalEData;
};
export var setGlobalECookie = function (countryISO, currencyCode, locale, regionCode) {
    var cookieValue = encodeURI(JSON.stringify({
        countryISO: countryISO,
        currencyCode: currencyCode,
        regionCode: regionCode,
        locale: locale,
    }));
    cookieWithConverter.set(GLOBALE_COOKIE, cookieValue, getGlobalECookieParams());
};
var isGlobalE = function () {
    // @ts-ignore
    var globalECountries = window.globalECountries || [];
    var globalEData = getGlobalEData();
    return globalEData && globalECountries.includes(globalEData.countryISO);
};
export var addGlobalEScripts = function () {
    // @ts-ignore
    var globalEJSUrl = window.globalEJSUrl;
    // @ts-ignore
    var globalECSSUrl = window.globalECSSUrl;
    var userAgentCountry = getUserAgentCountry();
    if (userAgentCountry) {
        setGlobalECookie(userAgentCountry.code, userAgentCountry.currency);
    }
    if (userAgentCountry || isGlobalE()) {
        if (globalEJSUrl) {
            var script = document.createElement('script');
            script.src = globalEJSUrl;
            script.async = true;
            document.body.appendChild(script);
        }
        if (globalECSSUrl) {
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = globalECSSUrl;
            document.body.appendChild(link);
        }
    }
};
export var handleGlobalEBanner = function (manual) {
    if (manual === void 0) { manual = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var banner, sites, globalEData, defaultCountry, defaultLocale, actualSiteCode, site, countries, bannerRoot;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isWebview())
                        return [2 /*return*/];
                    banner = document.getElementById(GLOBALE_BANNER_DIALOG);
                    if (!banner)
                        return [2 /*return*/];
                    if (window.localStorage.getItem('disableGlobalEBanner')) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, getSites()];
                case 1:
                    sites = _a.sent();
                    globalEData = getGlobalEData();
                    defaultCountry = getDefaultCountry();
                    if (!globalEData || !defaultCountry)
                        return [2 /*return*/];
                    defaultLocale = getLocale();
                    actualSiteCode = mapCountrySite[globalEData.countryISO];
                    if (!manual && actualSiteCode === banner.dataset.site) {
                        return [2 /*return*/];
                    }
                    if (!manual && GLOBALE_INITIALIZED) {
                        return [2 /*return*/];
                    }
                    site = sites.sites.find(function (s) { return s.code === actualSiteCode; });
                    countries = Object.entries(sites.mappingCountrySite).map(function (_a) {
                        var countryCode = _a[0], siteCode = _a[1];
                        var siteForCountry = sites.sites.find(function (s) { return s.code === siteCode; });
                        return {
                            code: countryCode,
                            locales: siteForCountry.availableLocales.map(function (locale) { return locale.code; }),
                            defaultLocale: siteForCountry.defaultLocale.code,
                        };
                    });
                    GLOBALE_INITIALIZED = true;
                    bannerRoot = createRoot(banner);
                    bannerRoot.render(React.createElement(GeolocDialog, { open: true, countries: countries, onClose: function () {
                            if (manual) {
                                window.location.reload();
                                return;
                            }
                            setGlobalECookie(defaultCountry.code, defaultCountry.currencyCode, defaultLocale, undefined);
                            fetch("/" + banner.dataset.site + "/cart/update", {
                                method: 'POST',
                                credentials: 'same-origin',
                            }).then(function () {
                                window.location.reload();
                            });
                        }, translations: {
                            title: translate('front.geoloc_dialog.title'),
                            subtitle: translate('front.geoloc_dialog.subtitle'),
                            selectCountry: translate('front.geoloc_dialog.select_country'),
                            selectLanguage: translate('front.geoloc_dialog.select_language'),
                            button: translate('front.geoloc_dialog.button'),
                            footer: translate('front.geoloc_dialog.footer'),
                        }, initialCountry: globalEData.countryISO, initialLocale: defaultLocale, onSubmit: function (country, language) {
                            var selectedCountry = GLOBALE_COUNTRIES_CURRENCIES.find(function (c) { return c.country === country; });
                            var redirectLink = '';
                            var linkSite = site || mapCountrySite[defaultCountry.code];
                            var cookieCountryCode = globalEData.countryISO;
                            var cookieCurrencyCode = globalEData.currencyCode;
                            var cookieRegionCode = globalEData.regionCode;
                            var regionCode = selectedCountry.country === cookieCountryCode ? cookieRegionCode : undefined;
                            if (selectedCountry) {
                                linkSite = mapCountrySite[country] || site;
                                cookieCountryCode = country;
                                cookieCurrencyCode = selectedCountry.currency;
                            }
                            var referer = window.location.pathname;
                            var searchParams = new URLSearchParams();
                            searchParams.append('refresh_order', 'true');
                            if (referer && referer !== '/') {
                                searchParams.append('referer', referer);
                            }
                            var searchParamsString = searchParams.toString();
                            redirectLink = "/" + linkSite + "-" + language + "/" + (searchParamsString ? "?" + searchParamsString : '');
                            setGlobalECookie(cookieCountryCode, cookieCurrencyCode, language, regionCode);
                            // add 50ms delay to let cookie to be set.
                            window.setTimeout(function () {
                                window.location.href = redirectLink;
                            }, 50);
                        } }));
                    analytics.popinGlobalE();
                    return [2 /*return*/];
            }
        });
    });
};
