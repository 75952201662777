import React from 'react';
import translate from '../../translate';
var PopinCentered = function (_a) {
    var popin = _a.popin;
    return (React.createElement("div", { id: "dialog-centered", className: "c-dialog c-dialog--centered c-dialog--box-md c-dialog--lg c-dialog--border-none", "data-popin-id": popin.uid, "data-only-once": popin.oncePerSession },
        React.createElement("div", { className: "c-dialog__inner" },
            React.createElement("button", { type: "button", "aria-label": translate('front.popin.close'), className: "c-dialog__close u-btn-unstyled", "data-dialog-hide": true },
                React.createElement("i", { className: "c-icon c-dialog__icon u-color-white@sm u-text-icon-xxs@sm" },
                    React.createElement("svg", { width: "22", height: "22", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53.34 53.34", className: "c-icon__svg", "aria-hidden": "true" },
                        React.createElement("path", { d: "M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" })))),
            React.createElement("div", { className: "o-grid o-grid--2 o-grid--1@sm o-grid--gutter-none" },
                React.createElement("div", { className: "o-grid__col u-flex u-flex-column" },
                    popin.mediaType === 'image' && (React.createElement("img", { src: popin.mediaUrl, width: "384", height: "314", className: "u-flex-item-auto u-cover", alt: popin.title })),
                    popin.mediaType === 'video' && (React.createElement("video", { width: "384", height: "314", className: "u-flex-item-auto u-cover", muted: true, autoPlay: true, loop: true },
                        React.createElement("source", { src: popin.mediaUrl + ".mp4", type: "video/mp4" }),
                        React.createElement("source", { src: popin.mediaUrl + ".webm", type: "video/webm" })))),
                React.createElement("div", { className: "o-grid__col u-flex" },
                    React.createElement("div", { className: "c-dialog__box u-flex u-flex-column u-flex-cross-center u-flex-main-center u-flex-item-auto" },
                        React.createElement("h2", { id: "dialog-centered-title", className: "u-h4-bis u-text-uppercase u-text-center" }, popin.title),
                        React.createElement("div", { className: "s-cms-popin-centered u-mt-xs-sm u-mt-xs@sm" },
                            React.createElement("div", { id: "dialog-centered-desc", className: "s-wysiwyg", dangerouslySetInnerHTML: { __html: popin.content } })),
                        popin.link && popin.ctaText && (React.createElement("div", { className: "u-mt-md u-mt-sm-bis@sm u-text-center" },
                            React.createElement("a", { href: popin.link, className: "c-btn", target: popin.target }, popin.ctaText)))))))));
};
export default PopinCentered;
