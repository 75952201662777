/* refresh search results via Ajax
 ========================================================================== */
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
/* eslint-disable */
import LazyLoad from 'vanilla-lazyload';
import debounce from 'lodash.debounce';
import addDialogs from '../dialogs/index';
import toggleWishlistItem from '../product/wishlist';
import addState from '../states/index';
import analytics from '../../analytics';
import { setHTMLWithScripts } from '../../utils';
import { setInputState } from './autoSizeInput';
import showLoader from '../loader';
import { cmsBlockProduct as sliderCmsBlockProduct } from '../sliders/sliders';

let currentSearch = '';
let currentController = null;

const updateSearchResults = (input, target, resultsPath) => {
  const value = encodeURIComponent(input.value);

  if (value.length < 3) {
    return;
  }

  if (currentSearch === value) {
    return; // Avoid double trigger
  }

  currentSearch = value;

  if (currentController) {
    currentController.abort();
  }

  currentController = new AbortController();
  const hideLoader = showLoader(target, { className: 'c-loader c-loader--large o-grid__col' });

  fetch(`${resultsPath}?q=${value}`, { signal: currentController.signal })
    .then((response) => response.text())
    .then((html) => {
      setHTMLWithScripts(target, html);

      new LazyLoad({
        elements_selector: '.js-lazy',
      });
      addDialogs();
      toggleWishlistItem();
      addState();
      sliderCmsBlockProduct();
      analytics.productsSearched(value, document.querySelectorAll('[data-testid="block-product"]').length);
      analytics.productListViewed('Search page');
    })
    .catch((err) => {
      if (err.name !== 'AbortError') {
        console.error(err);
      }
    })
    .finally(hideLoader);
};


export default function ajaxSearch() {
  const inputs = document.querySelectorAll('.js-product-search');

  inputs.forEach((input) => {
    const targetSelector = input.dataset.productSearchTarget;
    const resultsPath = input.dataset.productSearchPath;
    const target = targetSelector ? document.querySelector(targetSelector) : null;

    if (!target) {
      return;
    }

    const triggerSearch = debounce(() => {
      updateSearchResults(input, target, resultsPath);
    }, 1000);

    input.addEventListener('focus', triggerSearch);
    input.addEventListener('blur', triggerSearch);
    input.addEventListener('keyup', triggerSearch);
  });
}
