import React from 'react';
import translate from '../../translate';
import { ProductCta } from '../product/cta/ProductCta';
import routes from '../../routes';
var ID = 'dialog-popin';
var PopinClassic = function (_a) {
    var _b, _c;
    var popin = _a.popin;
    var colorPrimary = popin.colorPrimary;
    var colorSecondary = popin.colorSecondary;
    var dialogStyle = (_b = {},
        _b['--dialog-background-color'] = colorPrimary,
        _b.color = colorSecondary,
        _b);
    var closeIconStyle = {
        color: popin.closeCtaColor,
    };
    var buttonStyle = (_c = {},
        _c['--btn-background-color'] = colorSecondary,
        _c['--btn-color'] = colorPrimary,
        _c);
    return (React.createElement("div", { id: ID, className: "c-dialog c-dialog--modeless c-page__dialog js-dialog-sticky", "data-popin-id": popin.uid, "data-only-once": popin.oncePerSession, style: dialogStyle },
        React.createElement("div", { className: "c-dialog__inner c-overlay" },
            React.createElement("button", { type: "button", "aria-label": translate('front.popin.close'), className: "c-dialog__close u-btn-unstyled", "data-dialog-hide": true },
                React.createElement("i", { className: "c-icon c-dialog__icon u-text-icon-xxs", style: closeIconStyle },
                    React.createElement("svg", { width: "22", height: "22", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 53.34 53.34", className: "c-icon__svg", "aria-hidden": "true" },
                        React.createElement("path", { d: "M53.34 7.64L45.7 0 26.67 19.03 7.64 0 0 7.64l19.03 19.03L0 45.7l7.64 7.64 19.03-19.03L45.7 53.34l7.64-7.64-19.03-19.03L53.34 7.64z" })))),
            popin.mediaType === 'image' && popin.mediaUrl && (React.createElement("div", { className: "u-flex u-flex-column" },
                React.createElement("img", { src: popin.mediaUrl, width: "340", height: "276", className: "u-flex-item-auto u-cover", alt: popin.title, loading: "lazy" }))),
            popin.mediaType === 'video' && popin.mediaUrl && (React.createElement("div", { className: "u-flex u-flex-column" },
                React.createElement("video", { width: "340", className: "u-flex-item-auto u-cover", muted: true, autoPlay: true, loop: true, playsInline: true },
                    React.createElement("source", { src: popin.mediaUrl + ".mp4", type: "video/mp4" }),
                    React.createElement("source", { src: popin.mediaUrl + ".webm", type: "video/webm" })))),
            popin.mediaType === 'svg' && popin.mediaUrl && (React.createElement("div", { className: "u-flex u-flex-main-center u-pt-md u-pl-md u-pr-md" },
                React.createElement("img", { src: "/build/images/logo-popin/" + popin.mediaUrl, alt: popin.title, loading: "lazy" }))),
            React.createElement("div", { className: "c-dialog__box u-text-center" },
                React.createElement("h4", { id: "dialog-popin-title", className: "u-text-xxl u-line-height-sm u-text-uppercase" }, popin.title),
                React.createElement("div", { className: "s-cms-popin-classic u-mt-sm" },
                    React.createElement("div", { id: "dialog-popin-desc", className: "s-wysiwyg", dangerouslySetInnerHTML: { __html: popin.content } })),
                popin.link && !popin.addToCart && (React.createElement("a", { href: popin.link, className: popin.ctaText ? 'c-btn u-mt-xs-bis' : 'c-overlay__link', style: buttonStyle, target: popin.target }, popin.ctaText ? (popin.ctaText) : (React.createElement("span", { className: "u-visually-hidden" }, translate('front.popin.default_cta_text'))))),
                popin.product && popin.addToCart && (React.createElement("form", { action: routes.cart_add_product, method: "post", className: "u-mt-sm", "data-modal-id": ID },
                    React.createElement("input", { name: "ean[desktop][]", value: popin.product.selectedVariant.selectedItem.ean, className: "u-visually-hidden", type: "radio", checked: true }),
                    React.createElement(ProductCta, { onlyBasketCta: true, inDialogContext: true, allowAddedStatus: true, product: popin.product })))))));
};
export default PopinClassic;
